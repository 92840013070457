import { FaTag } from "react-icons/fa/";
import PropTypes from "prop-types";
import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import List from "../components/List";
import { FormattedMessage } from "react-intl";

const CategoryTemplate = props => {
  const {
    pageContext: { category, lang },
    data: {
      allMarkdownRemark: { totalCount, edges },
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;

  return (
    <React.Fragment>
      <ThemeContext.Consumer>
        {theme => (
          <Article theme={theme}>
            <header>
              <Headline theme={theme}>
                <span><FormattedMessage id="postsCategory"/></span> <FaTag/>
                {category}
              </Headline>
              {totalCount > 1 ?
                (<p className="meta">
                  <FormattedMessage id="potsCountPart1"/><strong>{totalCount}</strong><FormattedMessage
                  id="potsCountPart2"/>
                </p>)
                : (
                  <p className="meta">
                    <FormattedMessage id="potsCount1"/>
                  </p>
                )
              }


              <List edges={edges} theme={theme}/>
            </header>
          </Article>
        )}
      </ThemeContext.Consumer>

      <Seo facebook={facebook}/>
    </React.Fragment>
  );
};

CategoryTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired
};

export default CategoryTemplate;

// eslint-disable-next-line no-undef
export const categoryQuery = graphql`
  query PostsByCategoryAndLang($category: String, $lang: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___prefix], order: DESC }
      filter: { frontmatter: { category: { eq: $category } }, fields: {lang:{eq: $lang}} }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            lang
          }
          excerpt
          timeToRead
          frontmatter {
            title
            category
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
  }
`;
